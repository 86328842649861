import React from "react";
import { Link, NavLink } from "react-router-dom";

export const legalNotice = (
  <div className="text-center text-sm pt-10 pb-10 text-gray-700 font-medium">
    © SafetyCam
    <NavLink className="ml-5" to="/terms">
      Terms
    </NavLink>
    <NavLink className="ml-5" to="/privacy">
      Privacy
    </NavLink>
  </div>
);

export const notRegisteredYetNotice = (
  <div className="text-center text-sm mt-5 text-gray-700 font-medium">
    Not registered yet? <Link to="/register">Create an account</Link>.
  </div>
);
