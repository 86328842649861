import React from "react";
import { AiOutlineCode, AiOutlineLock, AiOutlineReload } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { wpEndpoint } from "constants/index";
import axios from "axios";
import { useHistory } from "react-router-dom";

const ResetPasswordSchema = Yup.object().shape({
  verify_code: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4)
    .max(4)
    .required("Enter your code."),
  password: Yup.string()
    .min(6, "Must be at least 6 characters long")
    .required("Enter your new password"),
  passwordRetyped: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

type Props = {
  userResetPasswordData: { email: string; verification_code: string } | null;
  setUserResetPasswordData: React.Dispatch<
    React.SetStateAction<{ email: string; verification_code: string } | null>
  >;
};

export default ({ userResetPasswordData, setUserResetPasswordData }: Props) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      verify_code: userResetPasswordData?.verification_code.toString(),
      password: "",
      passwordRetyped: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      // validate
      if (!values.verify_code || values.verify_code.length !== 4) {
        setSubmitting(false);
        return setStatus("Please check your verification code.");
      }
      if (values.password !== values.passwordRetyped) {
        setSubmitting(false);
        return setStatus("Passwords are not the same.");
      }
      setSubmitting(false);
      if (values.password.length < 5 || values.passwordRetyped.length < 5) {
        setSubmitting(false);
        return setStatus("Password length must be at least 6 characters");
      }

      if (!userResetPasswordData?.email) {
        setUserResetPasswordData(null);
        setStatus("Something went wrong. Please try again");
        setTimeout(() => {
          return history.push("/");
        }, 2000);
      }

      try {
        const response = await axios.post(
          `${wpEndpoint}/visuade/v1/verify_reset_password`,
          {
            password: values.password,
            verify_code: values.verify_code,
            email: userResetPasswordData?.email,
          }
        );
        if (response.data.user_code) {
          setUserResetPasswordData(null);
          history.push("/");
        } else {
          setStatus(response.data.message);
        }
      } catch (e) {
        setStatus("error message updating user form");
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        <div className="mb-4 relative">
          <input
            type="text"
            className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="verify_code"
            placeholder="1234"
            value={formik.values.verify_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <AiOutlineCode className="absolute left-0 top-2 text-xl" />
          {formik.errors.verify_code && formik.touched.verify_code && (
            <p className="text-red-500 mt-1 text-sm font-bold">
              <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
              {formik.errors.verify_code}
            </p>
          )}
        </div>

        <div className="mb-4 relative">
          <input
            type="password"
            className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="password"
            placeholder=""
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <AiOutlineLock className="absolute left-0 top-2 text-xl" />
          {formik.errors.password && formik.touched.password && (
            <p className="text-red-500 mt-1 text-sm font-bold">
              <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
              {formik.errors.password}
            </p>
          )}
        </div>

        <div className="mb-4 relative">
          <input
            type="password"
            className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="passwordRetyped"
            placeholder=""
            value={formik.values.passwordRetyped}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <AiOutlineLock className="absolute left-0 top-2 text-xl" />
          {formik.errors.passwordRetyped && formik.touched.passwordRetyped && (
            <p className="text-red-500 mt-1 text-sm font-bold">
              <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
              {formik.errors.passwordRetyped}
            </p>
          )}
        </div>

        {formik.status && (
          <p className="bg-red-500 text-white mb-2 rounded p-3">
            {formik.status}
          </p>
        )}

        <div className="flex justify-between mt-8">
          <button
            type="submit"
            className={classNames(
              "font-bold",
              "py-2",
              "px-4",
              "rounded",
              "focus:outline-none",
              "focus:shadow-outline",
              "w-full",
              {
                "bg-dark-grey-blue": !formik.isSubmitting,
                "hover:opacity-90": !formik.isSubmitting,
                "text-white": !formik.isSubmitting,
                "cursor-wait": formik.isSubmitting,
              }
            )}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
            ) : null}
            Set new password
          </button>
        </div>
      </>
    </form>
  );
};
