import axios from "axios";
import VideoPlayer from "components/VideoPlayer";
import React, { useEffect, useState, useCallback } from "react";
import { legalNotice } from "../common";
import useInterval from "@use-it/interval";
import { Emergency } from "types";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { wpEndpoint } from "constants/index";
import EmergencyCard from "components/EmergencyCard";

export default () => {
  const auth = useSelector((state: RootState) => state.auth);
  const [emergency, setEmergency] = useState<Emergency | null>(null);
  const [isVideoInactive, setIsVideoInactive] = useState<boolean | null>(null);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const playbackId = params.get("playback_id");
  const token = params.get("token");
  const emergency_row = params.get("emergency_row");
  const organisation_id = params.get("organisation_id");

  const getOrganisation = useCallback(async () => {
    if (emergency_row === null || organisation_id === null) return;
    try {
      let response = await axios.get(
        `${wpEndpoint}/visuade/v1/organisation/${parseInt(
          organisation_id,
          10
        )}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      const lastEmergency =
        response.data.emergencies[parseInt(emergency_row, 10) - 1];
      if (!lastEmergency) return;
      setEmergency(lastEmergency);
    } catch (e) {
      console.log("e", e);
    }
  }, [auth, emergency_row, organisation_id]);

  useEffect(() => {
    getOrganisation();
  }, [getOrganisation]);

  useInterval(() => {
    getOrganisation();
  }, 10000);

  useEffect(() => {
    const fn = async () => {
      try {
        const response = await axios.get(
          `https://stream.mux.com/${playbackId}.m3u8?token=${token}`
        );
        if (response) {
          //
        }
        setIsVideoInactive(false);
      } catch (err) {
        setIsVideoInactive(true);
      }
    };

    fn();
  }, [playbackId, token]);

  if (isVideoInactive === null || emergency === null) {
    return null;
  }

  if (isVideoInactive) {
    return (
      <div className="bg-gradient-brand min-h-full flex flex-1 justify-center items-center flex-col">
        <div className="font-bold m-5 ">This video is no longer available.</div>
        <a
          className="text-white tracking-tighter inline-block align-baseline font-bold px-4 py-2 text-xs uppercase mt-3 md:mt-0 mr-1 bg-blue-500 flex items-center rounded hover:opacity-90 hover:no-underline"
          href="/"
        >
          Go to dashboard
        </a>
      </div>
    );
  }

  return (
    <div
      className="bg-gradient-brand min-h-full flex items-center flex-col"
      style={{ backgroundColor: "rgb(241, 249, 255)" }}
    >
      <div className="font-bold m-5 ">
        This video is currently being livestreamed.
      </div>
      <div className="bg-white rounded shadow-lg w-full max-w-xl">
        {playbackId && token && (
          <>
            <VideoPlayer
              playbackId={playbackId}
              token={token}
              className="w-full"
              autoPlay
            />
            {emergency?.gps_locations.length > 0 && (
              <EmergencyCard emergency={emergency} />
            )}
          </>
        )}
      </div>

      {legalNotice}
    </div>
  );
};
