import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLock, AiOutlineMail, AiOutlineReload } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import imgLogo from "../images/logo.svg";
import bgVendingMachines from "../images/bg-vending-machines.png";
import { wpEndpoint } from "constants/index";
import { useDispatch } from "react-redux";
import { SetAuthAction } from "reducers/auth";

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Enter your password."),
  email: Yup.string().email("Invalid email.").required("Enter your email."),
});

export default () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      try {
        let response = await axios.post(`${wpEndpoint}/jwt-auth/v1/token`, {
          username: values.email,
          password: values.password,
        });

        const { token } = response.data.data;

        if (token) {
          setSubmitting(false);

          const action: SetAuthAction = {
            type: "set-auth-data",
            auth: {
              token,
              rememberMe: values.rememberMe,
              userData: null,
            },
          };

          dispatch(action);
        } else {
          throw new Error("Email or password was incorrect.");
        }
      } catch (e) {
        console.log("e", e);

        if (e.message) {
          setStatus(e.message);
        } else {
          setStatus("Could not connect to the server.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="bg-gradient-brand min-h-screen">
      <div className="flex h-full flex-col-reverse sm:flex-row">
        <div className="flex-1 md:min-h-screen">
          <div className="px-5 lg:px-20 py-5 bg-white w-full h-full">
            <img src={imgLogo} className="h-12" alt="Logo" />
            <h1 className="mb-10 mt-4">Welcome back!</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-8 relative">
                <input
                  type="text"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  placeholder="name@company.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlineMail className="absolute left-0 top-2 text-xl" />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.email}
                  </p>
                )}
              </div>

              <div className="mb-8 relative">
                <input
                  type="password"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="password"
                  placeholder="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlineLock className="absolute left-0 top-2 text-xl" />
                {formik.errors.password && formik.touched.password && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.password}
                  </p>
                )}
              </div>
              <div className="flex  justify-end">
                <Link
                  className="inline-block align-baseline font-bold text-sm"
                  to="/reset-password"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="flex mb-8">
                <div
                  className="block text-gray-700 font-bold cursor-pointer"
                  onClick={React.useCallback(
                    (e) => {
                      formik.setFieldValue(
                        "rememberMe",
                        !formik.values.rememberMe
                      );
                    },
                    [formik]
                  )}
                >
                  <input
                    className="mr-2 leading-tight cursor-pointer"
                    type="checkbox"
                    checked={formik.values.rememberMe}
                    readOnly
                  />
                  <span className="text-sm">Remember me</span>
                </div>
              </div>

              {formik.status && (
                <p className="bg-red-500 text-white mb-2 rounded p-3">
                  {formik.status}
                </p>
              )}

              <div className="flex justify-between mt-8 mb-4">
                <button
                  type="submit"
                  className={classNames(
                    "font-bold",
                    "py-2",
                    "px-4",
                    "rounded",
                    "focus:outline-none",
                    "focus:shadow-outline",
                    "w-full",
                    {
                      "bg-dark-grey-blue": !formik.isSubmitting,
                      "hover:bg-opacity-90": !formik.isSubmitting,
                      "text-white": !formik.isSubmitting,
                      "cursor-wait": formik.isSubmitting,
                    }
                  )}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
                  ) : null}
                  Log In
                </button>
              </div>
              <div className="text-sm text-gray-700 font-medium">
                No account yet? <Link to="/register">Sign up</Link>.
              </div>
            </form>
          </div>
        </div>
        <div
          className="flex-1 flex items-center pt-8 flex-col text-white"
          style={{
            background: `linear-gradient(0deg, rgba(47, 79, 107, 0.8), rgba(47, 79, 107, 0.8)), url(${bgVendingMachines})`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    </div>
  );
};
