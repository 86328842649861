import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

type Props = {
  markers: Array<GeolocationCoordinates>;
};

export default withScriptjs(
  withGoogleMap(({ markers }: Props) => {
    if (!markers) return null;

    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: markers[0].latitude, lng: markers[0].longitude }}
      >
        {markers.map((location, key) => {
          return (
            <Marker
              key={`key_${key}`}
              position={{ lat: location.latitude, lng: location.longitude }}
            />
          );
        })}
      </GoogleMap>
    );
  })
);
