import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
type Props = {
  playbackId: string;
  token: string;
  className: string;
  autoPlay?: boolean;
};

export default (props: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  let src = `https://stream.mux.com/${props.playbackId}.m3u8`;

  if (props.token) {
    src += `?token=${props.token}`;
  }

  useEffect(() => {
    let hls: Hls;
    const video = videoRef.current;
    if (video) {
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, src]);

  // Chromium browsers do not allow autoplay in most cases. However, muted autoplay is always allowed.
  return (
    <video
      controls
      ref={videoRef}
      className={props.className}
      autoPlay={props.autoPlay}
      muted={props.autoPlay}
    />
  );
};
