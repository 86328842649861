import React, { useState } from "react";
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import axios from "axios";
import { wpEndpoint } from "constants/index";

import Terms from "./routes/Terms";
import Privacy from "./routes/Privacy";
import Login from "./routes/Login";
import LogOut from "./routes/LogOut";
import Register from "./routes/Register";
import ResetPassword from "./routes/ResetPassword";
import Recordings from "./routes/Recordings";
import Organisation from "./routes/Organisation";
import { RootState } from "reducers/root";
import { useDispatch, useSelector } from "react-redux";
import Geocoder from "react-native-geocoding";
import { getUserData } from "actions/auth";
import BuyCredits from "routes/BuyCredits";
import Settings from "routes/Settings";
import PaymentSuccessful from "routes/PaymentSuccessful";
import AnonymousStream from "routes/AnonymousStream";

function App() {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [initialRoute, setInitialRoute] = useState<string | null>(null);

  React.useEffect(() => {
    const fn = async () => {
      if (auth?.token) {
        const response = await axios.post(
          `${wpEndpoint}/visuade/v1/delete_old_videos`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response) {
          //
        }
      }
    };
    fn();
  }, [auth]);

  React.useEffect(() => {
    if (!auth?.userData) {
      dispatch(getUserData());
    }
  }, [auth, dispatch]);

  React.useEffect(() => {
    if (!initialRoute) {
      setInitialRoute(location.pathname);
    }
  }, [initialRoute, location]);

  React.useEffect(() => {
    Geocoder.init("AIzaSyDtPzusqzd4NeuiKIMRKlXPux-xMVd3Q6o");
  }, []);

  const isAuthenticated = auth !== null;

  let organisations = auth?.userData?.member_of_these_organisations || [];
  // Make sure we are in the list and that we are verified
  const verifiedOrganisations = organisations.filter((org) => {
    return org.members.find((member) => {
      return member.id === auth?.userData?.user_info.id && member.verified;
    });
  });

  let organisation = verifiedOrganisations[0] ?? null;

  if (auth && !auth.userData) {
    return (
      <div className="w-full h-full items-center flex justify-center text-xl">
        <CgSpinner className="animate-spin h-16 w-16 mr-5 text-grey-blue" />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/stream" component={AnonymousStream} />

      {isAuthenticated && !organisation && (
        <Redirect from="/recordings" to="/organisation" />
      )}
      {isAuthenticated && organisation && (
        <Redirect from="/organisation" to="/recordings" />
      )}
      {isAuthenticated && organisation && (
        <Route exact path="/payment-successful" component={PaymentSuccessful} />
      )}
      {isAuthenticated && organisation && (
        <Route exact path="/buy-credits" component={BuyCredits} />
      )}
      {isAuthenticated && organisation && (
        <Route exact path="/settings" component={Settings} />
      )}

      {isAuthenticated && !organisation && (
        <Route exact path="/organisation" component={Organisation} />
      )}

      {isAuthenticated && organisation && (
        <Route exact path="/recordings" component={Recordings} />
      )}

      {isAuthenticated && <Route exact path="/logout" component={LogOut} />}
      {!isAuthenticated && <Route exact path="/login" component={Login} />}
      {!isAuthenticated && (
        <Route exact path="/reset-password" component={ResetPassword} />
      )}
      {!isAuthenticated && (
        <Route exact path="/register" component={Register} />
      )}

      {!isAuthenticated && <Redirect to="/login" />}

      {isAuthenticated && initialRoute ? (
        <Redirect
          exact
          from="/login"
          to={["/login", "/"].includes(initialRoute) ? "/" : initialRoute}
        />
      ) : null}
      {isAuthenticated && <Redirect exact from="/" to={"/recordings"} />}

      {/* {(!auth || (auth && auth.userData)) && <Route>404</Route>} */}
      {(!auth || (auth && auth.userData)) && <Redirect to={"/"} />}
    </Switch>
  );
}

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
