import React from "react";
import { wpEndpoint } from "constants/index";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/root";
import InviteMember from "components/InviteMember";
import { FaMinusCircle, FaUserAlt, FaUserTie } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import { getUserData } from "actions/auth";
import { Organisation } from "types";

type Props = {
  organisation: Organisation;
  isAdmin?: boolean;
};

export default ({ organisation, isAdmin }: Props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const [processRequest, setProcessRequest] = React.useState<boolean>(false);

  return (
    <div className="md:ml-5">
      <div className="mb-5 bg-white md:w-72 px-6 py-3 rounded-3xl">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-5">
          Credit balance: {organisation.credits}
        </h2>
      </div>

      <div className="mb-5 bg-white md:w-72 px-6 py-3 rounded-3xl">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-5">
          Members of {organisation.name}
        </h2>
        {processRequest && (
          <div className="w-full h-full items-center flex justify-center text-xl">
            <CgSpinner className="animate-spin h-16 w-16 mr-5 text-grey-blue" />
          </div>
        )}
        {!processRequest &&
          organisation.members
            .filter(
              (member) => member.display_name !== `spectator_${organisation.id}`
            )
            .map((member) => {
              return (
                <div className="flex" key={member.id}>
                  <div className="px-2">
                    {member.rank === "member" && <FaUserAlt />}
                    {member.rank === "admin" && <FaUserTie />}
                  </div>
                  <div className="w-full text-sm">
                    {member.display_name}

                    {!member.verified && ` (Unverified)`}
                  </div>
                  {((isAdmin && member.id !== auth?.userData?.user_info.id) ||
                    (!isAdmin &&
                      member.id === auth?.userData?.user_info.id)) && (
                    <div className="px-2">
                      <FaMinusCircle
                        className="hover:opacity-90 cursor-pointer"
                        onClick={async () => {
                          setProcessRequest(true);

                          const isConfirmed = window.confirm(
                            "Are you sure you wish to remove this member?"
                          );
                          if (!isConfirmed) return setProcessRequest(false);

                          let response = await axios.post(
                            `${wpEndpoint}/visuade/v1/organisation/remove`,
                            {
                              organisation_id: organisation.id,
                              user_id: member.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${auth?.token}`,
                              },
                            }
                          );

                          if (response) {
                            //
                          }

                          dispatch(getUserData());
                          setProcessRequest(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}

        {isAdmin && (
          <div className="bg-gray-50 rounded p-3 mt-3">
            <div className="font-bold mb-2 text-sm text-dark-grey-blue">
              Invite members
            </div>
            <InviteMember organisationId={organisation.id} />
          </div>
        )}
      </div>
    </div>
  );
};
