import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import classNames from "classnames";
import { useFormik } from "formik";
import axios from "axios";

import { wpEndpoint } from "constants/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { getUserData } from "actions/auth";
import { Organisation } from "types";

type Props = {
  organisation: Organisation;
};

export default (props: Props) => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  let organisations = auth?.userData?.organisation_invites || [];

  // Make sure we are in the list and that we are not verified
  organisations = organisations.filter((org) =>
    org.members.find(
      (member) => member.id === auth?.userData?.user_info.id && !member.verified
    )
  );

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      try {
        let response = await axios.post(
          `${wpEndpoint}/visuade/v1/organisation/join`,
          {
            organisation_id: props.organisation.id,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        if (response) {
          //
        }

        dispatch(getUserData());
      } catch (e) {
        console.log("e", e);

        if (e.message) {
          setStatus(e.message);
        } else {
          setStatus("Could not connect to the server.");
        }
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {organisations.length === 0
        ? "You currently do not have any pending invitations."
        : organisations.map((organisation) => {
            return (
              <div
                key={`key_${organisation.id}`}
                className="flex items-center justify-center flex-col"
              >
                You are invited to join {organisation.name}.
                <button
                  type="submit"
                  className={classNames(
                    "mt-3",
                    "font-bold",
                    "py-2",
                    "px-4",
                    "rounded",
                    "focus:outline-none",
                    "focus:shadow-outline",
                    {
                      "bg-dark-grey-blue": !formik.isSubmitting,
                      "hover:bg-opacity-90": !formik.isSubmitting,
                      "text-white": !formik.isSubmitting,
                      "cursor-wait": formik.isSubmitting,
                    }
                  )}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
                  ) : null}
                  Accept invitation
                </button>
              </div>
            );
          })}
    </form>
  );
};
