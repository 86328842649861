import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";

import imgLogo from "../images/logo_dark_bg.svg";
import bgVendingMachines from "../images/bg-vending-machines.png";

type Props = {
  isAdmin?: boolean;
};

export default ({ isAdmin }: Props) => {
  const auth = useSelector((state: RootState) => state.auth);
  const activeRoute = "border-y-10 underline";

  return (
    <div
      className="md:h-72 bg-center bg-cover"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(47, 79, 107, 0.8), rgba(47, 79, 107, 0.8)), url(${bgVendingMachines})`,
        // backgroundSize: "100px",
      }}
    >
      <div className="flex w-full max-w-screen-lg px-10 pt-10 mx-auto flex-col md:flex-row">
        <div className="flex flex-1">
          <NavLink to="/">
            <img src={imgLogo} className="h-8" alt="Logo" />
          </NavLink>
        </div>

        <div className="flex flex-col md:flex-row my-3 md:my-0">
          <NavLink
            activeClassName={activeRoute}
            className="text-white tracking-tighter inline-block align-baseline font-bold px-3 py-1 text-xs uppercase mt-3 md:mt-0 mr-1 flex items-center"
            to="/recordings"
          >
            Recordings
          </NavLink>
          {/*<NavLink
            className="text-white tracking-tighter inline-block align-baseline font-bold px-3 py-1 text-xs uppercase mt-3 md:mt-0 mr-1 flex items-center"
            to="/emergency-contacts"
          >
            Emergency Contacts
          </NavLink>*/}
          {isAdmin && (
            <NavLink
              activeClassName={activeRoute}
              className="text-white tracking-tighter inline-block align-baseline font-bold px-3 py-1 text-xs uppercase mt-3 md:mt-0 mr-1 flex items-center"
              to="/settings"
            >
              Settings
            </NavLink>
          )}
          <NavLink
            activeClassName={activeRoute}
            className="text-white tracking-tighter inline-block align-baseline font-bold px-3 py-1 text-xs uppercase mt-3 md:mt-0 mr-1 flex items-center"
            to="/logout"
          >
            Log out
          </NavLink>
          {isAdmin && (
            <NavLink
              //activeClassName={activeRoute}
              className="text-white tracking-tighter inline-block align-baseline font-bold px-4 py-2 text-xs uppercase mt-3 md:mt-0 mr-1 bg-blue-500 flex items-center rounded hover:opacity-90 hover:no-underline"
              to="/buy-credits"
            >
              Buy credits
            </NavLink>
          )}
        </div>
      </div>
      <div className="w-full max-w-screen-lg mx-auto text-white px-10 md:pt-10 pb-32">
        <h1>Dashboard</h1>
        Welcome back, {auth?.userData?.user_info.name}!
      </div>
    </div>
  );
};
