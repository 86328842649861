import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import Header from "components/Header";
import SideBar from "components/SideBar";
import { legalNotice } from "common";

type Props = {
  children: JSX.Element | Array<JSX.Element>;
};

export default ({ children }: Props) => {
  const auth = useSelector((state: RootState) => state.auth);

  const organisations = auth?.userData?.member_of_these_organisations || [];
  // Make sure we are in the list and that we are verified
  // const verifiedOrganisations = organisations.filter((org) => {
  //   return org.members.find((member) => {
  //     return member.id === auth?.userData?.user_info.id && member.verified;
  //   });
  // });
  const organisation = organisations[0] ?? null;

  const currentUserInOrganisation = organisation.members.find(
    (member) => member.id === auth?.userData?.user_info.id
  );
  const isAdmin =
    currentUserInOrganisation && currentUserInOrganisation.rank === "admin";

  return (
    <div>
      <Header isAdmin={isAdmin} />

      <div
        className="min-h-full"
        style={{
          backgroundColor: "#f1f9ff",
        }}
      >
        <div className="w-full max-w-screen-lg p-10 mx-auto">
          <div className="flex -mt-28 md:items-start flex-col md:flex-row">
            <div className="w-full">{children}</div>
            <SideBar organisation={organisation} isAdmin={isAdmin} />
          </div>
        </div>
        {legalNotice}
      </div>
    </div>
  );
};
