import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "reducers/root";
import { AiOutlineReload } from "react-icons/ai";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { wpEndpoint } from "constants/index";
import { getUserData } from "actions/auth";
import Page from "components/Page";

const SettingsSchema = Yup.object().shape({
  automaticReload: Yup.boolean(),
  reloadWhileStreaming: Yup.boolean(),
  nameOfOrganisation: Yup.string(),
});

export default () => {
  const [processRequest, setProcessRequest] = React.useState<boolean>(false);
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const organisations = auth?.userData?.member_of_these_organisations || [];
  const verifiedOrganisations = organisations.filter((org) => {
    return org.members.find((member) => {
      return member.id === auth?.userData?.user_info.id && member.verified;
    });
  });
  const organisation = verifiedOrganisations[0] ?? null;

  const currentUserInOrganisation = organisation.members.find(
    (member) => member.id === auth?.userData?.user_info.id
  );
  const isAdmin =
    currentUserInOrganisation && currentUserInOrganisation.rank === "admin";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      automaticReload: auth?.userData?.automatic_reload,
      reloadWhileStreaming: auth?.userData?.reload_while_streaming,
      nameOfOrganisation: organisation.name,
    },
    validationSchema: SettingsSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");
      try {
        let response = await axios.post(
          `${wpEndpoint}/visuade/v1/users/me/update_settings`,
          {
            automatic_reload: values.automaticReload,
            reload_while_streaming: values.reloadWhileStreaming,
            organisation_id: organisation.id,
            organisation_name: values.nameOfOrganisation,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        if (!response.data) {
          dispatch(getUserData());
        }
      } catch (e) {
        setStatus((e as Error).message);
      }
    },
  });

  if (auth?.userData === null) {
    return null;
  }

  return (
    <Page>
      <div className="pb-6 bg-white px-6 py-3 rounded-3xl">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
          Settings
        </h2>
        {!isAdmin ? (
          <div>
            <p>Please contact your admin to change settings</p>
          </div>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-8 relative">
                <div
                  className="block text-gray-700 font-bold cursor-pointer mb-2"
                  onClick={React.useCallback(
                    (e) => {
                      formik.setFieldValue(
                        "automaticReload",
                        !formik.values.automaticReload
                      );
                    },
                    [formik]
                  )}
                >
                  <p className="text-sm">Automatic credit reload</p>
                  <input
                    className="mr-2 leading-tight cursor-pointer"
                    type="checkbox"
                    checked={formik.values.automaticReload || false}
                    readOnly
                  />
                </div>
                <div
                  className="block text-gray-700 font-bold cursor-pointer mb-2"
                  onClick={React.useCallback(
                    (e) => {
                      formik.setFieldValue(
                        "reloadWhileStreaming",
                        !formik.values.reloadWhileStreaming
                      );
                    },
                    [formik]
                  )}
                >
                  <p className="text-sm">Automatic reload while streaming</p>
                  <input
                    className="mr-2 leading-tight cursor-pointer"
                    type="checkbox"
                    checked={formik.values.reloadWhileStreaming || false}
                    readOnly
                  />
                </div>
                <div className="block text-gray-700 font-bold mb-2">
                  <p className="text-sm">Organisation name</p>
                  <input
                    className="mr-2 leading-tight cursor-pointer"
                    type="text"
                    value={formik.values.nameOfOrganisation || ""}
                    onChange={React.useCallback(
                      (e) => {
                        formik.setFieldValue(
                          "nameOfOrganisation",
                          e.target.value
                        );
                      },
                      [formik]
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-8 mb-4">
                <button
                  type="submit"
                  className={classNames(
                    "font-bold",
                    "py-2",
                    "px-4",
                    "rounded",
                    "focus:outline-none",
                    "focus:shadow-outline",
                    "w-full",
                    {
                      "bg-dark-grey-blue": !formik.isSubmitting,
                      "hover:bg-opacity-90": !formik.isSubmitting,
                      "text-white": !formik.isSubmitting,
                      "cursor-wait": formik.isSubmitting,
                    }
                  )}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
                  ) : null}
                  Save
                </button>
              </div>
            </form>

            <div className="flex justify-between mt-8 mb-4">
              <button
                type="submit"
                className={classNames(
                  "font-bold",
                  "py-2",
                  "px-4",
                  "rounded",
                  "focus:outline-none",
                  "focus:shadow-outline",
                  "w-full",
                  {
                    "bg-dark-grey-blue": !processRequest,
                    "hover:bg-opacity-90": !processRequest,
                    "text-white": !processRequest,
                    "cursor-wait": processRequest,
                  }
                )}
                disabled={processRequest}
                onClick={async (e) => {
                  e.preventDefault();
                  setProcessRequest(true);
                  const isConfirmed = window.confirm(
                    "Are you sure you wish to delete this organisation?"
                  );
                  if (!isConfirmed) return setProcessRequest(false);
                  try {
                    let response = await axios.post(
                      `${wpEndpoint}/visuade/v1/organisation/delete`,
                      {
                        organisation_id: organisation.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${auth?.token}`,
                        },
                      }
                    );
                    if (response.data) {
                      alert("Organisation is successfully deleted!");
                      dispatch(getUserData());
                    }
                  } catch (err) {
                    console.log("[organisation video error] ", err.message);
                  }

                  setProcessRequest(false);
                }}
              >
                Delete organisation
              </button>
            </div>
          </>
        )}
      </div>
    </Page>
  );
};
