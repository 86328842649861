import React, { useEffect, useState } from "react";

import axios from "axios";
import { wpEndpoint } from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { Emergency, Organisation } from "types";
import VideoCard from "./VideoCard";

type Props = {
  organisation: Organisation;
};

export default (props: Props) => {
  const auth = useSelector((state: RootState) => state.auth);

  const [emergencies, setEmergencies] = useState<Array<Emergency> | null>(null);

  const correspondingOrganisation =
    auth?.userData?.member_of_these_organisations.find(
      (org) => org.id === props.organisation.id
    );

  const currentUserInOrganisation = correspondingOrganisation?.members.find(
    (member) => member.id === auth?.userData?.user_info.id
  );
  const isAdmin =
    currentUserInOrganisation && currentUserInOrganisation.rank === "admin";

  const getOrganisation = React.useCallback(async () => {
    try {
      let response = await axios.get(
        `${wpEndpoint}/visuade/v1/organisation/${props.organisation.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      setEmergencies(response.data.emergencies);
      // dispatch(getUserData());
    } catch (e) {
      console.log("e", e);
    }
  }, [auth, props.organisation.id]);

  useEffect(() => {
    getOrganisation();
  }, [getOrganisation]);

  if (!correspondingOrganisation) return null;

  return (
    <>
      <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
        Latest recordings
      </h2>
      <div className="grid grid-cols-2 gap-4">
        {emergencies === null && <>Loading...</>}
        {emergencies &&
          emergencies
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            .map((emergency) => {
              const nameOfUser = correspondingOrganisation.members.find(
                (member) => member.id === emergency.user
              );

              return emergency.playback_ids.map((playbackId, index) => {
                return (
                  <VideoCard
                    key={playbackId}
                    index={index}
                    playbackId={playbackId}
                    nameOfUser={nameOfUser}
                    emergency={emergency}
                    token={auth?.token}
                    getOrganisation={getOrganisation}
                    isAdmin={isAdmin}
                  />
                );
              });
            })}
        {emergencies && !emergencies.length && (
          <span>No recordings were found.</span>
        )}
      </div>
    </>
  );
};
