import React, { useState } from "react";

import axios from "axios";
import { DateTime } from "luxon";
import { Emergency, Member } from "types";
import { CgSpinner } from "react-icons/cg";
import { wpEndpoint } from "constants/index";
import VideoPlayer from "./VideoPlayer";

type Props = {
  playbackId: string;
  emergency: Emergency;
  index: number;
  token?: string;
  nameOfUser?: Member;
  getOrganisation: () => Promise<void>;
  isAdmin?: boolean;
};

export default ({
  playbackId,
  emergency,
  index,
  nameOfUser,
  token,
  getOrganisation,
  isAdmin,
}: Props) => {
  const [processRequest, setProcessRequest] = useState<boolean>(false);

  if (processRequest) {
    return (
      <div className="w-full h-full items-center flex justify-center text-xl">
        <CgSpinner className="animate-spin h-16 w-16 mr-5 text-grey-blue" />
      </div>
    );
  }

  return (
    <div className="flex flex-col" key={playbackId}>
      <VideoPlayer
        playbackId={playbackId}
        token={emergency.playback_tokens[index]}
        className="w-full"
      />

      <div className="text-xs text-dark-grey-blue mt-2">
        Recorded by {nameOfUser?.display_name} on{" "}
        {DateTime.fromISO(emergency.date).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS
        )}
      </div>
      <a
        className="text-xs text-dark-grey-blue mt-2 cursor-pointer"
        //href={`https://stream.mux.com/${playbackId}/medium.mp4?download=${playbackId}&token=${emergency.playback_tokens[index]}`}
        onClick={async (e) => {
          e.preventDefault();
          setProcessRequest(true);

          try {
            let response = await axios.post(
              `${wpEndpoint}/visuade/v1/users/download_video`,
              {
                video_id: emergency.active_asset_id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            console.log("response.data", response.data);

            if (response.data) {
              window.location.href = `https://stream.mux.com/${playbackId}/${response.data}?download=${emergency.date}&token=${emergency.playback_tokens[index]}`;
            }
            if (response.data.message) {
              alert(response.data.message);
            }
          } catch (err) {
            console.log("[download video error] ", err.message);
          }

          setProcessRequest(false);
        }}
      >
        Download
      </a>
      {isAdmin && (
        <a
          className="text-xs text-dark-grey-blue mt-2 cursor-pointer"
          onClick={async (e) => {
            e.preventDefault();
            setProcessRequest(true);
            const isConfirmed = window.confirm(
              "Are you sure you wish to delete this item?"
            );
            if (!isConfirmed) return setProcessRequest(false);
            try {
              let response = await axios.post(
                `${wpEndpoint}/visuade/v1/users/delete_video`,
                {
                  video_id: playbackId,
                  user_id: emergency.user,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (response) {
                alert("Video is succesfully deleted.");
              }
            } catch (err) {
              console.log("[delete video error] ", err.message);
            }

            if (getOrganisation) {
              await getOrganisation();
            }

            setProcessRequest(false);
          }}
        >
          Remove
        </a>
      )}
    </div>
  );
};
