import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { buyCredits, checkPayment } from "actions/payment";
import Page from "components/Page";

export default () => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const organisations = auth?.userData?.member_of_these_organisations || [];
  const verifiedOrganisations = organisations.filter((org) => {
    return org.members.find((member) => {
      return member.id === auth?.userData?.user_info.id && member.verified;
    });
  });
  const organisation = verifiedOrganisations[0] ?? null;

  useEffect(() => {
    if (!organisation) return;
    dispatch(checkPayment(organisation.id));
  }, [organisation, dispatch]);

  return (
    <Page>
      <div className="mb-5 bg-white px-6 py-3 pb-5 rounded-3xl flex flex-col">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
          Buy credits for {organisation.name}
        </h2>

        <button
          className="rounded bg-gray-200 hover:bg-opacity-90 p-2 mt-5"
          onClick={() => {
            dispatch(buyCredits(100, organisation.id));
          }}
        >
          100 credits for 10 €
        </button>

        <button
          className="rounded bg-gray-200 hover:bg-opacity-90 p-2 mt-5"
          onClick={() => {
            dispatch(buyCredits(200, organisation.id));
          }}
        >
          200 credits for 20 €
        </button>

        <button
          className="rounded bg-gray-200 hover:bg-opacity-90 p-2 mt-5"
          onClick={() => {
            dispatch(buyCredits(500, organisation.id));
          }}
        >
          500 credits for 50 €
        </button>
      </div>
    </Page>
  );
};
