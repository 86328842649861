import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { wpEndpoint } from "constants/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { getUserData } from "actions/auth";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("").required("Enter a valid email."),
});

type Props = {
  organisationId: number;
};

export default ({ organisationId }: Props) => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
      setStatus("");

      try {
        let response = await axios.post(
          `${wpEndpoint}/visuade/v1/organisation/invite`,
          {
            organisation_id: organisationId,
            email: values.email,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        if (response) {
          //
        }

        dispatch(getUserData());
        resetForm();
      } catch (e) {
        console.log("e", e);

        if (e.response?.data?.code) {
          setStatus(e.response?.data?.code);
        } else {
          setStatus("Could not connect to the server.");
        }
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-2 relative">
        <input
          type="text"
          className="appearance-none w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline"
          name="email"
          placeholder="name@example.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {/* <BsEnvelopeFill className="absolute left-0 top-2 text-xl" /> */}
        {formik.errors.email && formik.touched.email && (
          <p className="text-red-500 mt-1 text-sm font-bold">
            <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
            {formik.errors.email}
          </p>
        )}
      </div>

      {formik.status && (
        <div className="bg-red-100 border-t-2 border-red-500 border-solid mb-2 px-2">
          {formik.status}
        </div>
      )}
      <div className="flex justify-between">
        <button
          type="submit"
          className={classNames(
            "text-sm",
            "py-1",
            "px-@",
            "rounded",
            "focus:outline-none",
            "focus:shadow-outline",
            "w-full",
            {
              "bg-dark-grey-blue": !formik.isSubmitting,
              "hover:bg-opacity-90": !formik.isSubmitting,
              "text-white": !formik.isSubmitting,
              "cursor-wait": formik.isSubmitting,
            }
          )}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <AiOutlineReload className="animate-spin inline mr-2 -mt-1 " />
          ) : null}
          Send invite
        </button>
      </div>
    </form>
  );
};
