import React, { useEffect } from "react";

import { legalNotice } from "../common";
import imgLogo from "../images/logo.svg";
import { useDispatch } from "react-redux";
import { SetAuthAction } from "reducers/auth";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const action: SetAuthAction = {
      type: "set-auth-data",
      auth: null,
    };

    dispatch(action);
  }, [dispatch]);

  return (
    <div className="bg-gradient-brand min-h-full flex items-center flex-col">
      <img src={imgLogo} className="w-64 mb-8 mt-8" alt="Logo" />
      <div className="px-6 py-4 bg-white rounded shadow-lg w-full max-w-sm">
        <h1>Logging out...</h1>
      </div>
      {legalNotice}
    </div>
  );
};
