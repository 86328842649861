import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineLock,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useHistory } from "react-router-dom";

import imgLogo from "../images/logo.svg";
import bgVendingMachines from "../images/bg-vending-machines.png";
import { wpEndpoint } from "constants/index";
import axios from "axios";

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Enter your password."),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
  email: Yup.string().email("Invalid email.").required("Enter your email."),
  name: Yup.string().required("Enter your full name."),
  phone: Yup.string()
    .phone(undefined, undefined, "Phone number is not valid.")
    .required("Enter your phone number."),
  agreeTos: Yup.bool().oneOf([true], "You must agree to continue."),
});

export default () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      // name: "",
      // phone: "",
      // email: "",
      // password: "",
      // confirmPassword: "",
      // agreeTos: true,
      name: "Test Test",
      phone: "+32491333100",
      email: "test@flux.be",
      password: "qqq111",
      confirmPassword: "qqq111",
      agreeTos: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      try {
        const response = await axios.post(
          `${wpEndpoint}/visuade/v1/register_user`,
          {
            userName: values.name,
            userPhone: values.phone,
            userEmail: values.email,
            userPassword: values.password,
            fromWeb: true,
          }
        );

        alert("User created! Please login with your credentials.");

        const user_id = response.data.user_id;
        if (user_id && values.phone) {
          const response = await axios.post(
            `${wpEndpoint}/visuade/v1/contact/send_app_links`,
            {
              user_id,
              user_phone: values.phone,
            }
          );
          if (response.data.phone_number && response.data.response) {
            console.log("The links to the app are sent to you.");
          }
        }

        history.push("/login");
      } catch (e) {
        if (e.response.data.errors.length > 1) {
          setStatus(e.response.data.errors[0].message);
        } else {
          setStatus("Could not connect to the server.");
        }
      }

      setSubmitting(false);
    },
  });

  return (
    <div className="bg-gradient-brand min-h-screen">
      <div className="flex h-full flex-col-reverse sm:flex-row">
        <div className="flex-1 md:min-h-screen">
          <div className="px-5 lg:px-20 py-5 bg-white w-full h-full">
            <img src={imgLogo} className="h-12" alt="Logo" />
            <h1 className="mb-10 mt-4">Create an account</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-8 relative">
                <input
                  type="text"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="name"
                  placeholder="John Smith"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <AiOutlineUser className="absolute left-0 top-2 text-xl" />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.name}
                  </p>
                )}
              </div>

              <div className="mb-8 relative">
                <input
                  type="text"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="phone"
                  placeholder="+32 472 444 444"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlinePhone className="absolute left-0 top-2 text-xl" />

                {formik.errors.phone && formik.touched.phone && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.phone}
                  </p>
                )}
              </div>

              <div className="mb-8 relative">
                <input
                  type="text"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  placeholder="name@company.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlineMail className="absolute left-0 top-2 text-xl" />

                {formik.errors.email && formik.touched.email && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.email}
                  </p>
                )}
              </div>

              <div className="mb-8 relative">
                <input
                  type="password"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="password"
                  placeholder="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlineLock className="absolute left-0 top-2 text-xl" />
                {formik.errors.password && formik.touched.password && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.password}
                  </p>
                )}
              </div>

              <div className="mb-8 relative">
                <input
                  type="password"
                  className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <AiOutlineLock className="absolute left-0 top-2 text-xl" />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <p className="text-red-500 mt-1 text-sm font-bold">
                      <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                      {formik.errors.confirmPassword}
                    </p>
                  )}
              </div>

              <div className="mb-8">
                <div
                  className="block text-gray-700 font-bold cursor-pointer"
                  onClick={React.useCallback(
                    (e) => {
                      formik.setFieldValue("agreeTos", !formik.values.agreeTos);
                    },
                    [formik]
                  )}
                >
                  <input
                    className="mr-2 leading-tight cursor-pointer"
                    type="checkbox"
                    checked={formik.values.agreeTos}
                    readOnly
                  />
                  <span className="text-sm">
                    I agree with the{" "}
                    <a
                      target="blank"
                      className="inline-block align-baseline font-bold text-sm"
                      href="/terms"
                    >
                      terms and conditions
                    </a>{" "}
                    and{" "}
                    <a
                      target="blank"
                      className="inline-block align-baseline font-bold text-sm"
                      href="/privacy"
                    >
                      privacy policy
                    </a>
                    .
                  </span>
                </div>
                {formik.errors.agreeTos && formik.touched.agreeTos && (
                  <p className="text-red-500 mt-1 text-sm font-bold">
                    <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
                    {formik.errors.agreeTos}
                  </p>
                )}
              </div>

              {formik.status && (
                <p className="bg-red-500 text-white mb-2 rounded p-3">
                  {formik.status}
                </p>
              )}

              <div className="flex justify-between mb-4">
                <button
                  type="submit"
                  className={classNames(
                    "font-bold",
                    "py-2",
                    "px-4",
                    "rounded",
                    "focus:outline-none",
                    "focus:shadow-outline",
                    "w-full",
                    {
                      "bg-dark-grey-blue": !formik.isSubmitting,
                      "hover:opacity-90": !formik.isSubmitting,
                      "text-white": !formik.isSubmitting,
                      "cursor-wait": formik.isSubmitting,
                    }
                  )}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
                  ) : (
                    "Create account"
                  )}
                </button>
              </div>
              <div className="text-sm text-gray-700 font-medium">
                Already registered?{" "}
                <Link className="inline-block align-baseline" to="/login">
                  Log in to your account
                </Link>
                .
              </div>
            </form>
          </div>
        </div>
        <div
          className="flex-1 flex items-center pt-8 flex-col text-white"
          style={{
            background: `linear-gradient(0deg, rgba(47, 79, 107, 0.8), rgba(47, 79, 107, 0.8)), url(${bgVendingMachines})`,
          }}
        ></div>
      </div>
    </div>
  );
};
