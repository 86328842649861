import React, { useState } from "react";

import axios from "axios";
import { DateTime } from "luxon";
import { UserImage, Member } from "types";
import { CgSpinner } from "react-icons/cg";
import { wpEndpoint, baseUrl } from "constants/index";
import { getUserData } from "actions/auth";
import { useDispatch } from "react-redux";

type Props = {
  image: UserImage;
  token?: string;
  nameOfUser?: Member;
  getOrganisation: () => Promise<void>;
  isAdmin?: boolean;
};

export default ({
  image,
  nameOfUser,
  token,
  getOrganisation,
  isAdmin,
}: Props) => {
  const [processRequest, setProcessRequest] = useState<boolean>(false);
  const [hasImageError, setHasImageError] = useState<boolean>(false);
  const dispatch = useDispatch();

  if (processRequest) {
    return (
      <div className="w-full h-full items-center flex justify-center text-xl">
        <CgSpinner className="animate-spin h-16 w-16 mr-5 text-grey-blue" />
      </div>
    );
  }

  if (hasImageError) return null;

  return (
    <div className="flex flex-col" key={image.url}>
      <div className="relative">
        <a href={`${baseUrl}/${image.url}`} target={"_blank"} rel={"no-follow"}>
          <div className="absolute left-0 top-0 right-0 bottom-0 opacity-0 hover:opacity-100">
            <div className="absolute left-0 top-0 right-0 bottom-0 opacity-0 opacity-30 bg-white" />
          </div>

          <img
            alt={image.date}
            src={`${baseUrl}/${image.url}`}
            className="w-full"
            onError={({ currentTarget }) => {
              setHasImageError(true);
            }}
          />
        </a>
      </div>

      <div className="text-xs text-dark-grey-blue mt-2">
        Taken by {nameOfUser?.display_name} on{" "}
        {DateTime.fromISO(image.date).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS
        )}
      </div>

      {isAdmin && (
        <a
          className="text-xs text-dark-grey-blue mt-2 cursor-pointer"
          onClick={async (e) => {
            e.preventDefault();
            setProcessRequest(true);

            const userResponse = window.confirm("Are you sure?");
            if (!userResponse) return setProcessRequest(false);

            try {
              let response = await axios.post(
                `${wpEndpoint}/visuade/v1/users/delete_image`,
                {
                  image_url: image.url,
                  user_id: image.user,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (response.data.response) {
                alert("Succesfully deleted image!");
              }
            } catch (err) {
              console.log("[delete image error] ", err.message);
            }

            dispatch(getUserData());
            if (getOrganisation) {
              await getOrganisation();
            }
            setProcessRequest(false);
          }}
        >
          Remove
        </a>
      )}
    </div>
  );
};
