import React, { useEffect } from "react";

import { legalNotice } from "../common";
import imgLogo from "../images/logo.svg";
import { useHistory } from "react-router-dom";

export default (props: any) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 2000);
  }, [history]);

  console.log(props);

  return (
    <div className="bg-gradient-brand min-h-full flex items-center flex-col">
      <img src={imgLogo} className="w-64 mb-8 mt-8" alt="Logo" />
      <div className="px-6 py-4 bg-white rounded shadow-lg w-full max-w-sm">
        <h1>Payment successful!</h1>
      </div>
      {legalNotice}
    </div>
  );
};
