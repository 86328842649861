import React from "react";
import Page from "components/Page";

export default () => {
  return (
    <Page>
      <div className="pb-6 bg-white px-6 py-3 rounded-3xl">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
          ALGEMENE VERKOOPS-, LEVERINGS- EN BETALINGSVOORWAARDEN
        </h2>

        <h3 className="mb-6">Diensten aan particulier</h3>

        <p className="mb-6 text-sm">
          1. Behalve bijzondere voorwaarden, vermeld op de voorzijde van de
          factuur of van de bestelbon en ondertekend voor akkoord door de klant,
          zijn enkel en bij uitsluiting van elke andere, de voorwaarden van
          toepassing dewelke hieronder zijn vermeld.
        </p>

        <p className="mb-6 text-sm">
          2. De bestekken en prijsoffertes zijn gebaseerd op de thans geldende
          waarden van lonen, materialen en diensten. Indien deze wijzigingen
          ondergaan onafhankelijk van de wil van de aannemer, behoudt deze zich
          het recht voor de prijzen op evenredige wijze aan te passen. De klant
          heeft het recht om binnen de acht dagen nadat hij van deze
          prijswijziging is op de hoogte gebracht, de overeenkomst op te zeggen.
        </p>
        <p className="mb-6 text-sm">
          Prijsofferten zijn tot 30 (dertig) dagen na datum geldig en kunnen te
          allen tijde eenzijdig gewijzigd worden tot op het moment van
          aanvaarding door de klant. Prijzen worden steeds exclusief btw
          vermeld. Volgend op de aanvaarding door de klant zal de aannemer de
          overeenkomst schriftelijk confirmeren. De overeenkomst kan enkel
          gewijzigd worden mits schriftelijke overeenkomst tussen de aannemer en
          de klant. Niettegenstaande voornoemd principe zal de aannemer te allen
          tijde de prijzen eenzijdig kunnen aanpassen in functie van een
          substantiële wijziging van de grondstofprijzen of andere kosten met
          betrekking tot de uitvoering van de werken.
        </p>
        <p className="mb-6 text-sm">
          3. De leverings- en of uitvoeringstermijnen worden enkel bij wijze van
          inlichting verstrekt en zijn derhalve niet bindend tenzij aldus
          uitdrukkelijk tussen partijen overeengekomen. Vertraging in de
          uitvoering kan nooit aanleiding zijn tot boete, schadevergoeding of
          ontbinding van de overeenkomst.
        </p>
        <p className="mb-6 text-sm">
          De aannemer doet alle redelijke inspanningen om de diensten uit te
          voeren binnen de termijn vermeld in de overeenkomst. De klant erkent
          dat de vooropgestelde termijn een indicatief karakter heeft. Behoudens
          andersluidende schriftelijke overeenkomst kan een vertraging in de
          uitvoering geen aanleiding geven tot beëindiging van de overeenkomst
          of aanleiding geven tot enige schadevergoeding. Indien de aannemer een
          vertraging in de uitvoering verwacht, wordt de klant hier onverwijld
          van in kennis gesteld. De aannemer zal vervolgens in samenspraak met
          de klant tot een overeenkomst komen met betrekking tot de gepaste
          maatregelen. De voorziene uitvoerings- of leveringstermijn wordt
          opgeschort of verlengd ingevolge vorst, slecht weer en
          bevoorradingsproblemen.
        </p>
        <p className="mb-6 text-sm">
          Elke gebeurtenis die zich buiten de wil van een der partijen voordoet,
          die onvoorzienbaar is en niet veroorzaakt door nalatigheid of
          opzettelijke fout of inbreuk op de wetgeving, heeft tot gevolg dat de
          contractuele verplichtingen van de betrokken partij uitdooft.
        </p>

        <p className="mb-6 text-sm">
          4. Klachten inzake de conformiteit of de zichtbare gebreken
          betreffende de geleverde goederen en materialen moeten gebeuren per
          aangetekende zending, binnen de acht dagen na de beëindiging der
          werkzaamheden resp. de levering der materialen en in ieder geval voor
          ingebruikname.{" "}
        </p>
        <p className="mb-6 text-sm">
          5. Het protest tegen de factuur dient schriftelijk te gebeuren binnen
          de 30 dagen na de factuurdatum. Men wordt verzocht steeds de datum en
          het nummer van de factuur te vermelden. Facturen dewelke niet binnen
          de 30 dagen na ontvangst schriftelijk en gemotiveerd geprotesteerd
          worden, zullen als aanvaard worden beschouwd, zowel voor wat betreft
          de vermeldingen der facturen, als voor de specificaties der geleverde
          werken.
        </p>
        <p className="mb-6 text-sm">
          6. Alle facturen zijn contant betaalbaar, tenzij anders werd bedongen.
        </p>
        <p className="mb-6 text-sm">
          7. Bij nietbetaling van de factuur binnen de gestelde termijn is vanaf
          de eerste ingebrekestelling verwijlrente verschuldigd, gelijk aan de
          wettelijke intrest, 2% (2019). Bovendien is een forfaitaire
          schadevergoeding verschuldigd van 10% op het totale factuurbedrag, met
          een minimumbedrag van 40 euro.
        </p>
        <p className="mb-6 text-sm">
          Bij substantiële overschrijding van de betalingstermijn behoudt de
          aannemer zich het recht voor de overeenkomst eenzijdig te beëindigen
          onverminderd het recht op verdere schadevergoeding.
        </p>
        <p className="mb-6 text-sm">
          8. In geval van annulering van de overeenkomst is de klant eveneens
          een forfaitaire schadevergoeding verschuldigd ten belope van 10 % van
          de waarde van de bestelling, met een minimum van € ……….. en dit onder
          uitdrukkelijk voorbehoud van de mogelijkheid voor de verkoper om een
          hogere schadevergoeding te eisen. Wordt de overeenkomst geannuleerd
          door de aannemer, zal deze een gelijke vergoeding verschuldigd zijn
          aan de klant.
        </p>
        <p className="mb-6 text-sm">
          9. Zolang de geleverde materialen niet werden betaald, blijven zij
          eigendom van de aannemer. De overdracht van eigendom van de goederen
          door de aannemer geleverd in het kader van de werken, geschiedt
          slechts bij algehele en volledige betaling van deze goederen. Het is
          de klant verboden de geleverde en/of nog te leveren goederen in pand
          te geven, aan derden te verkopen of te vervreemden op welke wijze dan
          ook, zolang hij niet aan al zijn verplichtingen ten aanzien van de
          verkoper heeft voldaan; op eerste aanmaning dienen de goederen
          terugbezorgd te worden.
        </p>
        <p className="mb-6 text-sm">
          De aannemer behoudt zich het recht voor het voorrecht van onbetaalde
          verkoper op de door de aannemer verkochte goederen te doen gelden ten
          aanzien van derden, door vervulling van de daartoe door de wetgever
          gestelde formaliteiten. De risico-overdracht vindt plaats op het
          ogenblik van levering van de materialen.
        </p>
        <p className="mb-6 text-sm">
          10. In geval van betwisting zijn uitsluitend de rechtbanken bevoegd
          van de woonplaats van de klant of de rechtbank van de plaats waar de
          overeenkomst wordt uitgevoerd.
        </p>
        <p className="mb-6 text-sm">
          11. De overeenkomst kan door beide partijen zonder enige vergoeding
          worden beëindigd ingeval van onmogelijkheid tot uitvoering ervan ten
          gevolge van overmacht, staking, lock-out, staking van de andere
          partij, e.d..
        </p>
        <p className="mb-6 text-sm">
          12. De aannemer behoudt zich tevens het recht voor om de overeenkomst
          van rechtswege en zonder voorafgaande ingebrekestelling als ontbonden
          te beschouwen in geval van faillissement of kennelijk onvermogen van
          de klant. In geval van faillissement of kennelijk onvermogen van de
          klant, behoudt de aannemer zich het recht voor de goederen terug te
          nemen.
        </p>
        <p className="mb-6 text-sm">
          13. Onkosten verbonden aan onbetaalde wissels of cheques evenals
          andere inningskosten zijn in deze forfaitaire schadevergoeding niet
          begrepen en worden de klant afzonderlijk aangerekend.
        </p>
        <p className="mb-6 text-sm">
          14. Indien de aannemer zich verbindt tot het uitvoeren van werken
          en/of het leveren van diensten of prestaties dient één derde betaald
          te worden bij de ondertekening van de bestelbon , één derde bij de
          aanvang der werken/prestaties/diensten en één derde bij de oplevering
          of de voltooiing ervan.
        </p>
        <p className="mb-6 text-sm">
          15. Bij nietbetaling behoudt de aannemer zich het recht voor om
          verdere leveringen, prestaties en diensten stop te zetten.
        </p>
        <p className="mb-6 text-sm">
          16. Bij nietbetaling behoudt de aannemer zich het recht voor om de
          overeenkomst van rechtswege en zonder voorafgaande ingebrekestelling
          als ontbonden te beschouwen voor het geheel of het nog niet
          uitgevoerde gedeelte.
        </p>
        <p className="mb-6 text-sm">
          17. De aannemer kan slechts aansprakelijk gesteld worden voor
          kennelijk zware fouten, intentionele fouten en grove nalatigheden. De
          aannemer kan geenszins gehouden worden tot vergoeding van indirecte
          schade, economische schade of winstderving die het gevolg zijn van een
          door hem gemaakte inbreuk tegen een verplichting op basis van de
          overeenkomst of deze algemene voorwaarden. De aansprakelijkheid van de
          aannemer zal ten alle tijde beperkt blijven tot de som van de werken
          zoals vermeld in de overeenkomst.
        </p>
        <p className="mb-6 text-sm">
          18. De nietigheid, onuitvoerbaarheid of onafdwingbaarheid van één of
          meerdere bepalingen van deze algemene voorwaarden laat de geldigheid,
          de uitvoerbaarheid of afdwingbaarheid van de overige bepalingen
          onverlet. De rechten en verplichtingen die uit de overeenkomst
          voortvloeien, zullen noch gedeeltelijk noch in hun geheel op derden
          kunnen worden overgedragen, zonder de voorafgaande en schriftelijke
          toestemming van de aannemer. De niet-uitoefening door de aannemer of
          door de klant van haar rechten, op eender welk ogenblik, impliceert
          geenszins de verzaking aan die rechten.
        </p>
      </div>
    </Page>
  );
};
