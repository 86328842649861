import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import imgLogo from "../images/logo_dark_bg.svg";
import bgVendingMachines from "../images/bg-vending-machines.png";
import CreateOrganisation from "components/CreateOrganisation";
import JoinOrganisation from "components/JoinOrganisation";

export default () => {
  const auth = useSelector((state: RootState) => state.auth);

  let organisations = auth?.userData?.member_of_these_organisations || [];

  // Get a list of unverified organisations
  // const unverifiedOrganisations = organisations.filter((org) =>
  //   org.members.find(
  //     (member) => member.id === auth?.userData?.user_info.id && !member.verified
  //   )
  // );
  const unverifiedOrganisations = auth?.userData?.organisation_invites || [];

  return (
    <>
      <div
        className="md:h-72 bg-center bg-cover"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(47, 79, 107, 0.8), rgba(47, 79, 107, 0.8)), url(${bgVendingMachines})`,
          // backgroundSize: "100px",
        }}
      >
        <div className="flex w-full max-w-screen-lg px-10 pt-10 mx-auto flex-col md:flex-row">
          <div className="flex flex-1">
            <img src={imgLogo} className="h-8" alt="Logo" />
          </div>
          <div className="flex flex-col md:flex-row my-3 md:my-0">
            <Link
              className="text-white tracking-tighter inline-block align-baseline font-bold px-3 py-1 text-xs uppercase mt-3 md:mt-0 mr-1 flex items-center"
              to="/logout"
            >
              Log out
            </Link>
            <Link
              className="text-white tracking-tighter inline-block align-baseline font-bold px-4 py-2 text-xs uppercase mt-3 md:mt-0 mr-1 bg-blue-500 flex items-center rounded hover:opacity-90 hover:no-underline"
              to="/emergency-contacts"
            >
              Buy credits
            </Link>
          </div>
        </div>
        <div className="w-full max-w-screen-lg mx-auto text-white px-10 md:pt-10 pb-32">
          <h1>Welcome, {auth?.userData?.user_info.name}!</h1>
          You do not belong to an organisation yet. Please join or create an
          organisation.
        </div>
      </div>

      <div
        className="min-h-full"
        style={{
          backgroundColor: "#f1f9ff",
        }}
      >
        <div className="w-full max-w-screen-lg p-10 mx-auto">
          <div className="flex -mt-28 md:items-start flex-col md:flex-row">
            <div className="flex-1">
              <div className="bg-white flex-1 px-6 py-3 rounded-3xl mb-6">
                <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
                  Join an organisation
                </h2>
                {unverifiedOrganisations.length === 0
                  ? "You currently do not have any pending invitations."
                  : unverifiedOrganisations.map((organisation) => {
                      return (
                        <JoinOrganisation
                          key={`key_${organisation.id}`}
                          organisation={organisation}
                        />
                      );
                    })}
              </div>
              <div className="bg-white flex-1 px-6 py-3 rounded-3xl">
                <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
                  Create an organisation
                </h2>
                <CreateOrganisation />
              </div>
            </div>
            <div className="md:ml-5 md:w-72">
              <div className="bg-white flex-1 px-6 py-3 rounded-3xl">
                <h2 className="text-dark-grey-blue text-sm font-bold mb-5">
                  Account
                </h2>
                Coming soon!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
