import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import { BsFillExclamationTriangleFill, BsPeopleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { wpEndpoint } from "constants/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/root";
import { getUserData } from "actions/auth";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Your organisation must have at least 3 characters.")
    .max(30, "Your organisation must have at most 30 characters.")
    .required("Enter an organisation name."),
});

export default () => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      try {
        let response = await axios.post(
          `${wpEndpoint}/visuade/v1/organisation/create`,
          {
            name: values.name,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        if (response) {
          //
        }
        dispatch(getUserData());
      } catch (e) {
        if (e.message) {
          setStatus(e.message);
        } else {
          setStatus("Could not connect to the server.");
        }
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-8 relative">
        <input
          type="text"
          className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name="name"
          placeholder="My Cool Organisation"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <BsPeopleFill className="absolute left-0 top-2 text-xl" />
        {formik.errors.name && formik.touched.name && (
          <p className="text-red-500 mt-1 text-sm font-bold">
            <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
            {formik.errors.name}
          </p>
        )}
      </div>

      <div className="flex justify-between mt-8 mb-4">
        <button
          type="submit"
          className={classNames(
            "font-bold",
            "py-2",
            "px-4",
            "rounded",
            "focus:outline-none",
            "focus:shadow-outline",
            "w-full",
            {
              "bg-dark-grey-blue": !formik.isSubmitting,
              "hover:bg-opacity-90": !formik.isSubmitting,
              "text-white": !formik.isSubmitting,
              "cursor-wait": formik.isSubmitting,
            }
          )}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
          ) : null}
          Create organisation
        </button>
      </div>
    </form>
  );
};
