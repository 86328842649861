import React, { useEffect, useState } from "react";
import { Emergency } from "types";
import Geocoder from "react-native-geocoding";
import Map from "./Map";
import { DateTime } from "luxon";

type Coordinate = GeolocationCoordinates & {
  address: string;
};

type Props = {
  emergency: Emergency;
};

export default ({ emergency }: Props) => {
  const [coordinates, setCoordinates] = useState<Array<Coordinate> | null>(
    null
  );
  const [renderStart, setRenderStart] = useState<number>(0);

  useEffect(() => {
    const fn = async () => {
      if (emergency.gps_locations.length <= renderStart) return;

      setRenderStart(emergency.gps_locations.length);

      const newCoordinates = await Promise.all(
        emergency.gps_locations.slice(renderStart).map(async (location) => {
          const geoResult = await Geocoder.from(
            location.latitude,
            location.longitude
          );
          if (!geoResult) {
            throw new Error("not a real address found !");
          }
          return {
            ...location,
            address: geoResult.results[0].formatted_address,
          };
        })
      );

      setCoordinates(
        coordinates ? coordinates.concat(newCoordinates) : newCoordinates
      );
    };
    fn();
  }, [coordinates, emergency.gps_locations, renderStart]);

  if (!coordinates) return null;

  const lastCoordinate = renderStart <= 0 ? 0 : renderStart - 1;

  return (
    <div className="p-10">
      <div className="mb-6">
        <span className="font-bold">Livestream started at:</span>{" "}
        {DateTime.fromISO(emergency.date).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS
        )}
        <br />
        <span className="font-bold">Last seen:</span>{" "}
        {coordinates[lastCoordinate].address}
        <div>
          <a
            className="text-white tracking-tighter inline-block align-baseline font-bold px-4 py-2 text-xs uppercase mt-3 mr-1 bg-blue-500 items-center rounded hover:opacity-90 hover:no-underline"
            href={`http://www.google.com/maps/place/${coordinates[lastCoordinate].latitude},${coordinates[lastCoordinate].longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on map
          </a>
        </div>
      </div>

      <div className={"mb-6"}>
        <p className={"mb-3 font-bold"}>Coordinates:</p>
        <div className={"max-h-40 overflow-y-auto"}>
          {coordinates.length > 0 &&
            coordinates.map((location, key) => {
              return (
                <div className={"mb-3"} key={`key_${key}`}>
                  Latitude: {location.latitude} <br />
                  Longitude: {location.longitude} <br />
                  Address: {location.address}
                </div>
              );
            })}
        </div>
      </div>

      {coordinates.length > 0 && (
        <Map
          markers={coordinates}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCTkv6lH41hnwvc7x90RlgkiS6bU463mb4&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
    </div>
  );
};
