import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import OrganisationStreams from "components/OrganisationStreams";
import OrganisationImages from "components/OrganisationImages";
import Page from "components/Page";

export default () => {
  const auth = useSelector((state: RootState) => state.auth);

  const organisations = auth?.userData?.member_of_these_organisations || [];
  const verifiedOrganisations = organisations.filter((org) => {
    return org.members.find((member) => {
      return member.id === auth?.userData?.user_info.id && member.verified;
    });
  });
  const organisation = verifiedOrganisations[0] ?? null;

  return (
    <Page>
      <div className="mb-5 bg-white px-6 py-3 rounded-3xl">
        <OrganisationStreams organisation={organisation} />
      </div>
      <div className="mb-5 bg-white px-6 py-3 rounded-3xl">
        <OrganisationImages organisation={organisation} />
      </div>
    </Page>
  );
};
