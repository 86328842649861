import React, { useEffect, useState, useCallback } from "react";

import axios from "axios";
import { DateTime } from "luxon";

import { wpEndpoint } from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "reducers/root";
import ImageCard from "./ImageCard";

import { UserImage, Organisation } from "types";

type Props = {
  organisation: Organisation;
};

export default (props: Props) => {
  const auth = useSelector((state: RootState) => state.auth);

  const [images, setImages] = useState<Array<UserImage> | null>(null);

  const correspondingOrganisation =
    auth?.userData?.member_of_these_organisations.find(
      (org) => org.id === props.organisation.id
    );

  const currentUserInOrganisation = correspondingOrganisation?.members.find(
    (member) => member.id === auth?.userData?.user_info.id
  );
  const isAdmin =
    currentUserInOrganisation && currentUserInOrganisation.rank === "admin";

  const getOrganisation = useCallback(async () => {
    try {
      let response = await axios.get(
        `${wpEndpoint}/visuade/v1/organisation/${props.organisation.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (response.data.images) {
        setImages(response.data.images);
      }
    } catch (e) {
      console.log("[getOrganisation] e", e);
    }
  }, [auth, props.organisation.id]);

  useEffect(() => {
    getOrganisation();
  }, [getOrganisation]);

  if (!correspondingOrganisation) return null;

  return (
    <>
      <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
        Latest images
      </h2>
      <div className="grid grid-cols-3 gap-4">
        {images === null && <>Loading...</>}
        {images &&
          images
            .sort((a, b) => {
              return (
                DateTime.fromISO(b.date).toMillis() -
                DateTime.fromISO(a.date).toMillis()
              );
            })
            .map((image) => {
              const nameOfUser = correspondingOrganisation.members.find(
                (member) => member.id === image.user
              );

              return (
                <ImageCard
                  key={image.url}
                  image={image}
                  token={auth?.token}
                  nameOfUser={nameOfUser}
                  getOrganisation={getOrganisation}
                  isAdmin={isAdmin}
                />
              );
            })}
        {images && !images.length && <span>No images were found.</span>}
      </div>
    </>
  );
};
