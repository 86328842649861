import axios from "axios";
import { AnyAction } from "redux";
import { wpEndpoint } from "constants/index";
import { RootState } from "reducers/root";
import { ThunkAction } from "redux-thunk";
import { UserData } from "types";
import { SetAuthAction } from "reducers/auth";

export const getUserData = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;

    if (auth?.rememberMe || auth === null) {
      localStorage.setItem(
        "authData",
        JSON.stringify(
          auth
            ? {
                token: auth.token,
              }
            : null
        )
      );
    }

    if (auth?.token) {
      const response = await axios.get(`${wpEndpoint}/visuade/v1/users/me`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (
        ["jwt_auth_invalid_token", "jwt_auth_bad_iss"].includes(
          response.data.code
        )
      ) {
        const action: SetAuthAction = {
          type: "set-auth-data",
          auth: null,
        };

        dispatch(action);
      } else {
        const userData = response.data as UserData;

        const action: SetAuthAction = {
          type: "set-auth-data",
          auth: {
            ...auth,
            userData: userData,
          },
        };

        dispatch(action);
      }
    }
  };
};
