import axios from "axios";
import { AnyAction } from "redux";
import { wpEndpoint } from "constants/index";
import { RootState } from "reducers/root";
import { ThunkAction } from "redux-thunk";

export const buyCredits = (
  amount: number,
  organisation: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;

    if (auth?.rememberMe || auth === null) {
      localStorage.setItem(
        "authData",
        JSON.stringify(
          auth
            ? {
                token: auth.token,
              }
            : null
        )
      );
    }

    if (!auth?.token) {
      return;
    }

    try {
      const response = await axios.post(
        `${wpEndpoint}/visuade/v1/payment/create`,
        {
          amount,
          organisation,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const data = response.data as {
        checkoutUrl: string;
        id?: string;
      };

      // if (data.id) {
      //   localStorage.setItem("paymentId", data.id);
      // }

      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      }
    } catch (err) {
      console.log("payment create error", err.message);
    }
  };
};

export const checkPayment = (
  organisation_id: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;

    if (!auth?.token) {
      return;
    }

    const response = await axios.post(
      `${wpEndpoint}/visuade/v1/payment/check`,
      {
        organisation_id,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    if (response.data === true) {
      alert("Thanks for purchasing credits!");
    }
  };
};
