import React from "react";
import { AiOutlineMail, AiOutlineReload } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { wpEndpoint } from "constants/index";
import axios from "axios";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email.").required("Enter your email."),
});

type Props = {
  setUserResetPasswordData: React.Dispatch<
    React.SetStateAction<{ email: string; verification_code: string } | null>
  >;
};

export default ({ setUserResetPasswordData }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      setStatus("");

      try {
        var emailPattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(String(values.email).toLowerCase())) {
          setSubmitting(false);
          return setStatus("Email is not valid");
        }

        try {
          const response = await axios.post(
            `${wpEndpoint}/visuade/v1/reset_password_link`,
            { email: values.email }
          );

          if (response.data.message) {
            setStatus(response.data.message);
          }
          if (response.data.verification_code) {
            const userResetPasswordData = {
              email: values.email,
              verification_code: response.data.verification_code,
            };
            setUserResetPasswordData(userResetPasswordData);
          }
        } catch (e) {
          setStatus("Error message updating user form");
        }
      } catch (e) {
        console.log("e", e);
        if (e.message) {
          setStatus(e.message);
        } else {
          setStatus("Could not connect to the server.");
        }
      }

      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <>
        <div className="mb-4 relative">
          <input
            type="text"
            className="pl-8 appearance-none border-solid border-b-2 border-dark-grey-blue w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            placeholder="name@company.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <AiOutlineMail className="absolute left-0 top-2 text-xl" />
          {formik.errors.email && formik.touched.email && (
            <p className="text-red-500 mt-1 text-sm font-bold">
              <BsFillExclamationTriangleFill className="inline mr-2 -mt-1" />
              {formik.errors.email}
            </p>
          )}
        </div>

        {formik.status && (
          <p className="bg-red-500 text-white mb-2 rounded p-3">
            {formik.status}
          </p>
        )}

        <div className="flex justify-between mt-8">
          <button
            type="submit"
            className={classNames(
              "font-bold",
              "py-2",
              "px-4",
              "rounded",
              "focus:outline-none",
              "focus:shadow-outline",
              "w-full",
              {
                "bg-dark-grey-blue": !formik.isSubmitting,
                "hover:opacity-90": !formik.isSubmitting,
                "text-white": !formik.isSubmitting,
                "cursor-wait": formik.isSubmitting,
              }
            )}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <AiOutlineReload className="animate-spin inline mr-2 -mt-1" />
            ) : null}
            Send reset code
          </button>
        </div>
      </>
    </form>
  );
};
