import { UserData } from "types";

export type AuthData = {
  token: string;
  rememberMe: boolean;
  userData: UserData | null;
} | null;

export type SetAuthAction = {
  type: "set-auth-data";
  auth: AuthData;
};

let initialState: AuthData = null;

if (localStorage.getItem("authData")) {
  initialState = JSON.parse(
    localStorage.getItem("authData") || 'null'
  );
}

const urlParams = new URLSearchParams(window.location.search);    
const tokenFromUrl = urlParams.get('tempToken');

if (tokenFromUrl) {
  initialState = {
    token: decodeURIComponent(tokenFromUrl),
    rememberMe: false,
    userData: null
  }
}

export default (
  state: AuthData = initialState,
  action: SetAuthAction
): AuthData => {
  switch (action.type) {
    case "set-auth-data":
      if (action.auth) {
        return {
          ...state,
          ...action.auth,
        };
      } else {
        return null;
      }
    default:
      return state;
  }
};
