import Page from "components/Page";
import React from "react";

export default () => {
  return (
    <Page>
      <div className="pb-6 bg-white px-6 py-3 rounded-3xl">
        <h2 className="text-dark-grey-blue text-sm font-bold mb-12">
          Privacy Policy
        </h2>
      </div>
    </Page>
  );
};
