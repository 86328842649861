import React from "react";
import { Link } from "react-router-dom";
import imgLogo from "../images/logo.svg";
import bgVendingMachines from "../images/bg-vending-machines.png";

import SetNewPasswordForm from "components/SetNewPasswordForm";
import ResetPasswordForm from "components/ResetPasswordForm";

export default () => {
  const [userResetPasswordData, setUserResetPasswordData] = React.useState<{
    email: string;
    verification_code: string;
  } | null>(null);

  return (
    <div className="bg-gradient-brand min-h-screen">
      <div className="flex h-full flex-col-reverse sm:flex-row">
        <div className="flex-1 md:min-h-screen">
          <div className="px-5 lg:px-20 py-5 bg-white w-full h-full">
            <img src={imgLogo} className="h-12" alt="Logo" />
            <h1 className="mb-10 mt-4">Reset password</h1>
            {userResetPasswordData?.email ? (
              <SetNewPasswordForm
                userResetPasswordData={userResetPasswordData}
                setUserResetPasswordData={setUserResetPasswordData}
              />
            ) : (
              <ResetPasswordForm
                setUserResetPasswordData={setUserResetPasswordData}
              />
            )}
            <div className="text-center text-sm mt-5 text-gray-700 font-medium">
              <Link className="ml-5" to="/login">
                Return to login screen
              </Link>
            </div>
          </div>
        </div>
        <div
          className="flex-1 flex items-center pt-8 flex-col text-white"
          style={{
            background: `linear-gradient(0deg, rgba(47, 79, 107, 0.8), rgba(47, 79, 107, 0.8)), url(${bgVendingMachines})`,
          }}
        ></div>
      </div>
    </div>
  );
};
